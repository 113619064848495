@use "colors" as *;
@use "typography" as *;

// Breakpoints
@mixin breakpoint-up($point) {
  @if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: 576px) {
      @content;
    }
  }
}

//Mixins
@mixin flex(
  $direction: column,
  $justifyContent: flex-start,
  $alignItems: flex-start
) {
  align-items: $alignItems;
  box-sizing: border-box;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

@mixin flexRow($alignX: flex-start, $alignY: flex-start, $wrap: wrap) {
  @include flex(
    $direction: row,
    $justifyContent: $alignX,
    $alignItems: $alignY
  );
  flex-wrap: $wrap;
}

@mixin flexColumn($alignX: flex-start, $alignY: flex-start) {
  @include flex($justifyContent: $alignY, $alignItems: $alignX);
}

@mixin fontFormat($size: $fontBody, $weight: 500, $color: $darkGray) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin pageLayout($size: 1030px) {
  max-width: $size;
  margin: auto;
  padding-right: 16px;
  padding-left: 16px;

  @include breakpoint-up(md) {
    max-width: $size - 300px;
  }

  @include breakpoint-up(lg) {
    max-width: $size - 50px;
  }
}

@mixin imageDiv($size: cover, $width: 300px, $height: 100px) {
  background-color: $light;
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size;
  border-radius: 4px;
  height: $height;
  width: $width;
}

@mixin buttonClean() {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

@mixin buttonCustom(
  $background: "",
  $color: "",
  $onHover: "",
  $shadow: $onHover
) {
  background-color: $background;
  color: $color;
  // top: 0px;
  transition-property: top, box-shadow, background-color;
  transition-duration: 0.2s;
  &:hover,
  &:focus {
    background-color: $onHover;
    box-shadow: 0 4px 4px -3px $shadow;
    transition-property: top, box-shadow, background-color;
    transition-duration: 0.2s;
    // top: -2px;
  }
  &:hover:disabled {
    background-color: $background;
  }
}

@mixin linkWithHover() {
  color: $link;
  cursor: pointer;

  &:hover {
    color: $linkHover;
  }
}
