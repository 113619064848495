.MQKn7q_hidden {
  visibility: hidden;
  white-space: nowrap;
  font-size: 1em;
  position: absolute;
}

.MQKn7q_error {
  color: var(--palette-warning, red);
}

.MQKn7q_error b {
  font-weight: 900;
}

.MQKn7q_overlayBadge {
  background-color: var(--palette-primary, #204e8c);
  text-align: center;
  box-sizing: border-box;
  z-index: 3;
  border-left: 1px solid var(--palette-border, #d9d9d9);
  border-bottom: 1px solid var(--palette-border, #d9d9d9);
  border-radius: 0 0 0 10px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 30px;
  padding: 4px;
  font-weight: 900;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.MQKn7q_overlayBadge .MQKn7q_icon {
  width: 15px;
  height: 15px;
}

.MQKn7q_phonesCard .MQKn7q_overlayBadge {
  background-color: #f44336;
}

.MQKn7q_phonesCard .MQKn7q_logo_container {
  position: relative;
}

.MQKn7q_phonesCard .MQKn7q_logo_container .MQKn7q_logo_image {
  position: absolute;
  top: 0;
}

.MQKn7q_phonesCard .MQKn7q_phonesTitle {
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 1.2em;
  display: flex;
}

.MQKn7q_phonesCard .MQKn7q_overlayTitle {
  color: #000;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 115px;
  padding: 5px;
  font-size: .8em;
  font-weight: bold;
  position: relative;
}

.MQKn7q_phonesCard .MQKn7q_tapButton {
  margin-top: 10px;
}

.MQKn7q_smsCard .MQKn7q_overlayBadge {
  background-color: #4caf50;
}

.MQKn7q_smsCard .MQKn7q_logo_container {
  position: relative;
}

.MQKn7q_smsCard .MQKn7q_logo_container .MQKn7q_logo_image {
  position: absolute;
  top: 0;
}

.MQKn7q_smsCard .MQKn7q_smsTitle {
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 1.2em;
  display: flex;
}

.MQKn7q_smsCard .MQKn7q_overlayTitle {
  color: #000;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 115px;
  padding: 5px;
  font-size: .8em;
  font-weight: bold;
  position: relative;
}

.MQKn7q_smsCard .MQKn7q_tapButton {
  margin-top: 10px;
}

.MQKn7q_defaultCard .MQKn7q_overlayBadge {
  background-color: var(--palette-primary, #204e8c);
}

.MQKn7q_defaultCard .MQKn7q_overlayTitle {
  color: var(--palette-mute, #00000060);
  justify-content: flex-start;
  padding-bottom: 12px;
  font-size: 12px;
  font-weight: normal;
  background-color: #0000 !important;
}

.MQKn7q_defaultCard .MQKn7q_cardContentContainer:hover .MQKn7q_overlayTitle {
  color: var(--palette-link, #007bff);
}

.MQKn7q_items {
  box-sizing: border-box;
  background-color: var(--palette-gray, #f3f5f7);
  border-radius: 5px;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  width: 100%;
  padding: 32px 16px;
  display: flex;
}

.MQKn7q_items .MQKn7q_card {
  box-sizing: border-box;
  background-color: var(--palette-warm-white, #faf8f2);
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  box-shadow: 0 3px 8px #0000003d;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_cardContentContainer {
  box-sizing: border-box;
  border-bottom: 1px solid var(--palette-border, #d9d9d9);
  color: var(--palette-link, #007bff);
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_cardContentContainer .MQKn7q_overlayTitle {
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_cardContentContainer:hover .MQKn7q_overlayTitle {
  text-decoration: underline;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_cardContentContainer:hover .MQKn7q_logo_image {
  transition: all .2s;
}

.MQKn7q_items .MQKn7q_card svg {
  width: auto;
  height: 56px;
  margin: 16px 0;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_detailContainer {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_detailContainer:hover .MQKn7q_title {
  color: var(--palette-link, #007bff);
}

.MQKn7q_items .MQKn7q_card .MQKn7q_detailContainer:hover .MQKn7q_detail {
  color: var(--palette-link, #007bff);
  text-decoration: underline;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_detailContainer .MQKn7q_title {
  margin-top: 10px;
  padding: 10px 10px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_detailContainer .MQKn7q_detail {
  color: var(--palette-mute, #00000060);
  padding: 10px;
  font-size: 12px;
}

.MQKn7q_items .MQKn7q_card p {
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_top {
  box-sizing: border-box;
  text-transform: uppercase;
  opacity: .5;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-size: 12px;
  display: flex;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_icons svg {
  cursor: pointer;
  fill: #002677;
  width: 24px;
  height: auto;
  margin: 0;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_icons svg:hover {
  filter: brightness(2);
}

.MQKn7q_items .MQKn7q_card .MQKn7q_logo_container {
  width: 100%;
  position: relative;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_logo_container .MQKn7q_logo_image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 120px;
  height: 115px;
  transition: all .2s;
}

.MQKn7q_items .MQKn7q_card .MQKn7q_arrow svg {
  width: 16px;
  margin-inline-start: 4px;
}

.MQKn7q_footer {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  display: flex;
}

.MQKn7q_footer .MQKn7q_showMoreButton {
  background-color: var(--palette-gray, #f3f5f7);
  border: 1px solid var(--palette-border, #d9d9d9);
  color: var(--palette-primary, #204e8c);
  cursor: pointer;
  text-align: center;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
}

.MQKn7q_footer .MQKn7q_showMoreButton:hover {
  background-color: var(--palette-warm-white, #faf8f2);
}

._2C9xpa_root {
  box-sizing: border-box;
  width: 100%;
}

.chatbot_cef8c5 {
  width: 100%;
  height: 100%;
}

.chatbot_cef8c5 ::-webkit-scrollbar {
  width: 5px;
}

.chatbot_cef8c5 ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.chatbot_cef8c5 ::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 10px;
}

@media (width >= 1200px) {
  .chatbot_cef8c5 ::-webkit-scrollbar {
    width: 8px;
  }
}

.chatbot_cef8c5 .rsc-container {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.chatbot_cef8c5 .rsc-content {
  color-scheme: light;
  box-sizing: border-box;
  flex-flow: column;
  flex-grow: 1;
  margin-top: 0;
  display: flex;
  overflow-y: auto;
}

.chatbot_cef8c5 .rsc-content::-webkit-scrollbar {
  width: 4px;
}

.chatbot_cef8c5 .rsc-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.chatbot_cef8c5 .rsc-ts-bot {
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 0 24px;
  line-height: 20px;
}

.chatbot_cef8c5 .rsc-ts-bot:first-child {
  padding-top: 24px;
  margin-top: auto !important;
}

.chatbot_cef8c5 .rsc-ts-bot:last-child {
  margin-bottom: 0;
  padding-bottom: 24px;
}

.chatbot_cef8c5 .rsc-ts-bubble {
  background-color: var(--palette-warm-white, #faf8f2);
  max-width: 380px;
  margin: 0;
  font-family: HelveticaNeue, Arial, sans-serif;
}

.chatbot_cef8c5 .rsc-footer {
  background-color: var(--palette-gray, #f3f5f7);
}

.chatbot_cef8c5 .rsc-input {
  padding-left: 35px;
}

.chatbot_cef8c5 .rsc-ts-image {
  object-fit: contain;
  background-color: var(--palette-avatar-background, #e6eff0);
  width: 40px;
  height: 40px;
}

.chatbot_cef8c5 .eval-root {
  position: relative;
}

.chatbot_cef8c5 .eval-bubble {
  background-color: #ffecbc;
  padding: 12px;
  position: absolute;
  top: -12px;
  left: -12px;
  right: -12px;
}

.chatbot_cef8c5 .rsc-os {
  background-color: var(--palette-gray, #f3f5f7);
  z-index: 100;
  padding: 16px;
  position: sticky;
  bottom: 0;
}

.chatbot_cef8c5 .rsc-os-options {
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  display: flex;
}

.chatbot_cef8c5 .rsc-os-option {
  margin-inline-end: 4px;
}

.chatbot_cef8c5 .rsc-ts-user {
  margin-bottom: 30px;
  padding: 0 24px;
}

.chatbot_cef8c5 .rsc-ts-user:last-child {
  margin-bottom: 0;
  padding-bottom: 24px;
}

.chatbot_cef8c5 .rsc-ts-user .rsc-ts-bubble {
  background-color: "#054e91";
}

@media (width >= 768px) {
  .chatbot_cef8c5 .rsc-cs {
    padding: 20px;
  }
}

.chatbot_cef8c5 .rsc-cs .rsc-ts-bubble {
  background-color: "#054e91";
  font-size: 14px;
}

.chatbot_cef8c5 .rsc-cs .rsc-loading {
  background-color: #f5f5f5;
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 16px;
  display: flex;
  box-shadow: 0 1px 2px #00000026;
}

.chatbot_cef8c5 .rsc-cs:first-child {
  margin-top: auto;
}

.xUVbMa_header {
  border-bottom: 1px solid var(--palette-border, #d9d9d9);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  min-height: 60px;
  padding-bottom: 5px;
  display: flex;
}

@media (width >= 576px) {
  .xUVbMa_header {
    flex-direction: row;
  }
}

.xUVbMa_header .xUVbMa_logo_container {
  width: 100%;
}

.xUVbMa_header .xUVbMa_logo_container img {
  max-width: 160px;
  height: 100%;
  max-height: 120px;
}

.xUVbMa_header .xUVbMa_title {
  color: var(--palette-primary, #204e8c);
  white-space: pre-line;
  font-size: 16px;
  font-weight: 600;
}

.xUVbMa_header .xUVbMa_titleContainer a.xUVbMa_website {
  color: var(--palette-link, #007bff);
  padding: 10px 0;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  display: block;
}

.xUVbMa_header .xUVbMa_titleContainer a.xUVbMa_website:hover {
  opacity: .8;
  text-decoration: underline;
}

.xUVbMa_body {
  color: var(--palette-text-light, #666);
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  line-height: 24px;
  display: flex;
}

.xUVbMa_body a {
  color: var(--palette-link, #007bff);
  text-decoration: none;
}

.xUVbMa_body a:hover {
  opacity: .8;
  text-decoration: underline;
}

.xUVbMa_body .xUVbMa_phone {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
}

.xUVbMa_body .xUVbMa_phone_miles {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.xUVbMa_body .xUVbMa_address .xUVbMa_navigate {
  margin-left: 10px;
}

.xUVbMa_body .xUVbMa_payments ul, .xUVbMa_body .xUVbMa_services ul {
  margin: 0;
  padding: 0;
}

.xUVbMa_body .xUVbMa_payments ul li, .xUVbMa_body .xUVbMa_services ul li {
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  display: flex;
}

.xUVbMa_body .xUVbMa_payments ul li img, .xUVbMa_body .xUVbMa_services ul li img {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.xUVbMa_body .xUVbMa_title {
  color: var(--palette-primary, #204e8c);
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

.xUVbMa_body .xUVbMa_icon {
  fill: var(--palette-primary, #204e8c);
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.k9B-tq_flag {
  object-fit: contain;
  border: 1px solid var(--palette-background, #fff);
  height: 16px;
}

.k9B-tq_root {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.k9B-tq_root .k9B-tq_header {
  border-bottom: 1px solid var(--palette-border, #d9d9d9);
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  height: 60px;
  margin-bottom: 10px;
  padding: 0 20px;
  display: grid;
}

.k9B-tq_root .k9B-tq_header .k9B-tq_headerButton {
  cursor: pointer;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-bottom: 5px solid #0000;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 60px;
  margin-bottom: -3px;
  font-size: 14px;
  transition: all .2s ease-in-out;
  display: flex;
}

.k9B-tq_root .k9B-tq_header .k9B-tq_headerButton.k9B-tq_active {
  border-bottom: 5px solid var(--palette-primary, #204e8c);
  transition: all .2s ease-in-out;
}

.k9B-tq_root .k9B-tq_header .k9B-tq_headerButton:hover {
  border-bottom: 5px solid var(--palette-alternative, #ff9811);
  transition: all .2s ease-in-out;
}

.k9B-tq_root .k9B-tq_body {
  flex: 1;
}

.k9B-tq_tabActive {
  display: block;
}

.k9B-tq_tabInactive {
  display: none;
}

.k9B-tq_listContainer {
  flex-direction: column;
  margin: auto;
  padding: 10px;
  display: flex;
}

.k9B-tq_listContainer .k9B-tq_item {
  cursor: pointer;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  transition: all .2s;
  display: flex;
}

.k9B-tq_listContainer .k9B-tq_item .k9B-tq_itemText {
  color: var(--palette-text-light, #666);
  align-items: center;
  gap: 10px;
  display: flex;
}

.k9B-tq_listContainer .k9B-tq_item:hover {
  background-color: var(--palette-primary, #204e8c);
  transition: all .2s;
}

.k9B-tq_listContainer .k9B-tq_item:hover .k9B-tq_itemText {
  color: var(--palette-background, #fff);
}

.k9B-tq_listContainer .k9B-tq_item .k9B-tq_itemIcon {
  display: none;
}

.k9B-tq_listContainer .k9B-tq_item.k9B-tq_active .k9B-tq_itemText {
  color: var(--palette-text, #333);
  font-weight: 600;
}

.k9B-tq_listContainer .k9B-tq_item.k9B-tq_active .k9B-tq_itemText span {
  border-bottom: 1px solid var(--palette-text-light, #666);
}

.k9B-tq_listContainer .k9B-tq_item.k9B-tq_active .k9B-tq_itemIcon {
  display: flex;
}

.k9B-tq_listContainer .k9B-tq_item.k9B-tq_active:hover .k9B-tq_itemText {
  color: var(--palette-background, #fff);
  border-bottom: 1px solid var(--palette-background, #fff);
}

.k9B-tq_itemIcon {
  width: 20px;
  height: 20px;
  mask-size: cover;
  mask-repeat: no-repeat;
}

.k9B-tq_itemIcon svg {
  fill: var(--palette-alternative, #ff9811);
}

.k9B-tq_languageIcon {
  fill: var(--palette-primary, #204e8c);
  height: 20px;
  display: flex;
}

._7DEjqq_show {
  opacity: 1;
  transition: all .2s;
  left: 0;
}

._7DEjqq_hide {
  opacity: 0;
  transition: all .2s;
  left: -100%;
}

._7DEjqq_root {
  background-color: #00000090;
  background-color: var(--palette-warm-white, #faf8f2);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

._7DEjqq_root ._7DEjqq_ModalScrollContainer {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden auto;
}

.IZOd5a_show {
  display: flex;
}

.IZOd5a_hide {
  display: none;
}

.IZOd5a_error {
  color: red;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

@media (width >= 768px) {
  .IZOd5a_root.IZOd5a_right_bottom {
    right: var(--offset-chat-x, 20px);
    bottom: var(--offset-chat-y, 20px);
  }

  .IZOd5a_root.IZOd5a_left_bottom {
    left: var(--offset-chat-x, 20px);
    bottom: var(--offset-chat-y, 20px);
  }

  .IZOd5a_root.IZOd5a_right_top {
    right: var(--offset-chat-x, 20px);
    top: var(--offset-chat-y, 20px);
  }

  .IZOd5a_root.IZOd5a_left_top {
    left: var(--offset-chat-x, 20px);
    top: var(--offset-chat-y, 20px);
  }
}

.IZOd5a_float.IZOd5a_right_bottom {
  right: var(--offset-x, 20px);
  bottom: var(--offset-y, 20px);
}

.IZOd5a_float.IZOd5a_left_bottom {
  left: var(--offset-x, 20px);
  bottom: var(--offset-y, 20px);
}

.IZOd5a_float.IZOd5a_right_top {
  right: var(--offset-x, 20px);
  top: var(--offset-y, 20px);
}

.IZOd5a_float.IZOd5a_left_top {
  left: var(--offset-x, 20px);
  top: var(--offset-y, 20px);
}

.IZOd5a_float, .IZOd5a_root {
  font-size: 14px;
}

.IZOd5a_float {
  font-family: HelveticaNeue, Arial, sans-serif;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.IZOd5a_float .IZOd5a_floatContainer {
  cursor: pointer;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  display: flex;
}

.IZOd5a_float .IZOd5a_floatContainer .IZOd5a_floatAvatar {
  box-shadow: 0 0 5px 0px var(--palette-text, #333);
  background-color: var(--palette-avatar-background, #e6eff0);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.IZOd5a_float .IZOd5a_floatContainer .IZOd5a_floatAvatar img {
  object-fit: contain;
  border-radius: 50%;
  width: 90%;
  height: 90%;
}

.IZOd5a_float .IZOd5a_floatContainer .IZOd5a_assistant_name {
  color: var(--palette-background, #fff);
  text-align: center;
  background-color: var(--palette-primary, #204e8c);
  border: 1px solid var(--palette-border, #d9d9d9);
  border-radius: 10px;
  margin-top: -12px;
  padding: 5px 20px;
  font-size: 14px;
  transition: all .2s;
}

.IZOd5a_float .IZOd5a_floatContainer:hover .IZOd5a_floatAvatar {
  box-shadow: 0 0 10px 0px var(--palette-text, #333);
  transition: all .2s;
}

.IZOd5a_float .IZOd5a_floatContainer:hover .IZOd5a_assistant_name {
  background-color: var(--palette-primary-hover, #376aad);
  transition: all .2s;
}

.IZOd5a_root {
  box-sizing: border-box;
  backdrop-filter: blur(3px);
  background-color: #fff8;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  font-family: HelveticaNeue, Arial, sans-serif;
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

@media (width >= 768px) {
  .IZOd5a_root {
    border: 1px solid var(--palette-border, #d9d9d9);
    border-radius: 10px;
    min-width: 360px;
    max-width: 500px;
    height: 90dvh;
  }
}

.IZOd5a_root .IZOd5a_header {
  background-color: var(--palette-primary, #204e8c);
  height: 90px;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  display: flex;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_zoomerContainer {
  display: flex;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_zoomerContainer .IZOd5a_iconCloseContainer {
  cursor: pointer;
  height: 60px;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_zoomerContainer .IZOd5a_iconCloseContainer img {
  width: 40px;
  height: 40px;
  margin: 10px;
  transition: transform .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_zoomerContainer .IZOd5a_iconCloseContainer:hover img {
  transition: transform .2s;
  transform: scale(1.1);
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting {
  cursor: pointer;
  opacity: .6;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting .IZOd5a_iconSettingContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-left: 15px;
  display: flex;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting .IZOd5a_iconSettingContainer img {
  width: 16px;
  height: 16px;
  margin: 10px;
  transition: transform .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting .IZOd5a_settingText {
  color: var(--palette-warm-white, #faf8f2);
  font-size: 14px;
  transition: all .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting:hover {
  opacity: 1;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting:hover img {
  transition: transform .2s;
  transform: scale(1.1);
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconSetting:hover .IZOd5a_settingText {
  transition: all .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconBack {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 60px;
  font-size: 20px;
  display: flex;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconBack img {
  width: 40px;
  height: 40px;
  margin: 10px;
  transition: transform .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconBack:hover img, .IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconBack:hover .IZOd5a_backText {
  opacity: .8;
  transition: all .2s;
}

.IZOd5a_root .IZOd5a_header .IZOd5a_headerContainer .IZOd5a_iconBack .IZOd5a_backText {
  color: var(--palette-warm-white, #faf8f2);
  font-weight: 600;
  transition: all .2s;
}

.IZOd5a_root .IZOd5a_chatboxContainer {
  flex-grow: 1;
  height: calc(100% - 90px);
  margin-top: -40px;
  padding: 10px;
}

.IZOd5a_root .IZOd5a_chatbox {
  background-color: var(--palette-background, #fff);
  border: 1px solid var(--palette-border, #d9d9d9);
  box-sizing: border-box;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden auto;
}

.IZOd5a_root ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.IZOd5a_RTL {
  text-align: right;
  direction: rtl;
}

.IZOd5a_LTR {
  text-align: left;
  direction: ltr;
}
/*# sourceMappingURL=rivara-ai.css.map */
